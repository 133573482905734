@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "AcuminProSemibold";
  src: url("/fonts/Acumin_Pro_Cond_Semibold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AcuminProMedium";
  src: url("/fonts/Acumin_Pro_Cond_Medium.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}
body {
  margin: 0;
  font-family: "AcuminProSemibold", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "AcuminProSemibold", sans-serif;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
}

.flex-grow {
  flex-grow: 1;
}

img,
iframe {
  display: block;
}

.iframe {
  display: block;
  width: 100%;
  height: 100%;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.arrowContainer {
  position: relative;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.arrow {
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 auto;
}

.arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 20px;
  height: 20px;
  border-bottom: 2px solid #ed2f3d;
  border-right: 2px solid #ed2f3d;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(5px);
  }
  40% {
    transform: translateY(10px);
  }
  60% {
    transform: translateY(7px);
  }
}

.bounce {
  animation: bounce 2s infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* .loader {
  display: inline-grid;
  padding: 5px;
  background: #fff;
  filter: blur(4px) contrast(12);
}
.loader:before {
  content: "";
  height: 40px;
  aspect-ratio: 3;
  --c: #0000 64%, #ed2f3d 66% 98%, #0000 101%;
  background: radial-gradient(35% 146% at 50% 159%, var(--c)) 0 0,
    radial-gradient(35% 146% at 50% -59%, var(--c)) 100% 100%;
  background-size: calc(200% / 3) 50%;
  background-repeat: repeat-x;
  animation: l11 0.8s infinite linear;
}
@keyframes l11 {
  to {
    background-position: -200% 0, -100% 100%;
  }
} */

.loader {
  width: 35px;
  height: 80px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 20px;
}
.loader:before {
  content: "";
  position: absolute;
  inset: 0 0 20px;
  padding: 1px;
  background: conic-gradient(
      from -90deg at calc(100% - 3px) 3px,
      #7e3c26 135deg,
      #341612 0 270deg,
      #0000 0
    ),
    conic-gradient(
      from 0deg at 3px calc(100% - 3px),
      #0000 90deg,
      #341612 0 225deg,
      #7e3c26 0
    ),
    #54281f;
  background-size: 17px 17px;
  background-clip: content-box;
  --c: no-repeat linear-gradient(#000 0 0);
  -webkit-mask: var(--c) 0 0, var(--c) 17px 0, var(--c) 0 17px,
    var(--c) 17px 17px, var(--c) 0 34px, var(--c) 17px 34px,
    linear-gradient(#000 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  animation: l6 3s infinite;
}
.loader:after {
  content: "";
  position: absolute;
  inset: 60% 0 0;
  background: #b21b01;
  border-top: 5px solid #c9c7c5;
}
@keyframes l6 {
  0%,
  14% {
    -webkit-mask-size: 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, auto;
  }
  15%,
  29% {
    -webkit-mask-size: 17px 17px, 0 0, 0 0, 0 0, 0 0, 0 0, auto;
  }
  30%,
  44% {
    -webkit-mask-size: 17px 17px, 17px 17px, 0 0, 0 0, 0 0, 0 0, auto;
  }
  45%,
  59% {
    -webkit-mask-size: 17px 17px, 17px 17px, 17px 17px, 0 0, 0 0, 0 0, auto;
  }
  60%,
  74% {
    -webkit-mask-size: 17px 17px, 17px 17px, 17px 17px, 17px 17px, 0 0, 0 0,
      auto;
  }
  75%,
  89% {
    -webkit-mask-size: 17px 17px, 17px 17px, 17px 17px, 17px 17px, 17px 17px,
      0 0, auto;
  }
  90%,
  100% {
    -webkit-mask-size: 17px 17px, 17px 17px, 17px 17px, 17px 17px, 17px 17px,
      17px 17px, auto;
  }
}
